import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  Header,
  TopNav,
  Footer,
  PageInfo,
} from "../../custom";

const Partnership = () => {
    const [isActive, setActive] = React.useState(false)
    return (
      <Fragment>
        <Helmet>
          <title>Partnership</title>
          <meta name="description" content="PartnerShip" />
        </Helmet>
        <TopNav />
        <Header />
        <PageInfo title="PartnerShip" bgPicture="url(img/bg-info/bg.jpg)" />
  
          <div class="container" style={{marginTop: 100}}>
            <div className="row" style={{marginBottom: 50, backgroundColor: '#F5F5F5', padding: 20 }}>
              <div className="col-md-2" >
                <img src="http://ssl.gstatic.com/accounts/ui/avatar_1x.png" class="avatar img-circle img-thumbnail" alt="avatar" />
              </div>
              <div class="col-sm-6 col-md-4">
                <br/>
                <h4>Bhaumik Patel</h4>
                <small><cite title="San Francisco, USA">  <i class="glyphicon glyphicon-map-marker">
                </i> Nigeria, Lagos</cite></small>
                <p>
                    <i class="glyphicon glyphicon-envelope"></i> email@example.com
                    <br />
                    <i class="glyphicon glyphicon-phone"></i> +2348066379681
                </p>
              </div>
              {!isActive ? (
              <div class="col-sm-12 col-md-2" style={{marginTop: 60}}>
                {/* <button type="button" class="btn btn-primary">Upload Receipt</button> {'   '} */}
                <button type="button" class="btn btn-primary" onClick={()  => setActive(true)}>Pay Your Tithe</button>
              </div>
              ): (
                <div className="row">
                  <div className="col-md-3 col-sm-8" style={{marginTop: 60}}>
                    <input type="text" className="form-control" placeholder="Enter Amount" />
                  </div>

                  <div className="col-md-1 col-sm-2" style={{marginTop: 60}}>
                    <button type="button" class="btn btn-primary">Pay</button>
                  </div>
                </div>
              )}
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">   
                  <table id="mytable" class="table table-bordred table-striped">     
                    <thead>
                      <th>Month</th>
                      <th>Date</th>
                      <th>Amount Paid</th>           
                    </thead>
                    <tbody>
                      <tr>
                        <td>January</td>
                        <td>01/06/2020</td>
                        <td>50,000</td>
                      </tr>
                      
                      <tr>
                        <td>February</td>
                        <td>11/02/2020</td>
                        <td>50,000</td>
                      </tr>
           
                      <tr>
                        <td>August</td>
                        <td>12/08/2020</td>
                        <td>50,000</td>
                       </tr>
                
                    </tbody>
                  </table>  
                </div>
              </div>
            </div>
          </div>
        <Footer />
      </Fragment>
    );
  }

export default Partnership;
