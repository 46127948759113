import React from 'react';


// eslint-disable-next-line import/prefer-default-export
export const ContainerSize = {
  minHeight: '60vh',
  paddingTop: '50px',
  paddingBottom: '50px',
};

export const Top = {
  borderRadius: '10px',
  backgroundColor: '#eee',
  boxShadow: '25px solid gray',
  padding: '20px',
};

export const Image = {
  height: '200px',

};

export const widget = {
 paddingBottom: '10px'
};


export const marginTop = {
  marginTop: '50px',
};
